import { memo, useState } from 'react'
import useInterval from './../../utils/useInterval'
import './../../styles/Header.scss'

const getFormattedTime = () => {
  const now = new Date()
  const min = now.getMinutes()
  return `${('0' + now.getDate()).slice(-2)}/${(
    '0' +
    now.getMonth() +
    1
  ).slice(-2)} ${now.getHours()}:${min < 10 ? '0' + min : min}`
}

const Clock = memo(function Clock() {
  const [time, setTime] = useState(getFormattedTime())

  useInterval(() => {
    setTime(getFormattedTime())
  }, 1000)

  return (
    <div className="clock">
      <span>{time}</span>
    </div>
  )
})

export default Clock
